"use strict";

import * as UIUtils from "../ui_utils";
import React, { useEffect, useRef, useState } from "react";
import CommonSecurity from "../../server/common/generic/common_security";
import CommonUtils from "../../server/common/generic/common_utils";
import CommonURLs from "../../server/common/generic/common_urls";
import CompanyLoginHeader from "../widgets/headers/company_login_header";
import ErrorBar from "../widgets/bars/error_bar";
import MFASetup from "./accountManagement/mfa_setup";
import UserNewPasswordPopup from "./passwordManagement/user_new_password_popup";
import UserNewSigningPinPopup from "./passwordManagement/user_new_signing_pin_popup";
import FooterBar from "../widgets/bars/footer_bar";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { RetryPleaseError, RetryWrapper } from "../helpers/retry_wrapper";
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";
import * as CognitoHelper from "../helpers/cognito_helper";
import { Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { UsernamePasswordForm } from "./login/username_password_form";
import { MFASubmission } from "./login/mfa_submission";

const Logger = Log.group(LOG_GROUP.Users, "UserLogin");
const HEADERS = {
  mfaSubmission: <span id="pageTitleBar">Multi-Factor Authentication</span>,
  mfaSetup: <span id="pageTitleBar">Set up Multi-Factor Authentication</span>,
  defaultHeader: <span id="pageTitleBar">Welcome back to
                  <span className="qbd"> QbD</span>
                  <span className="vision">Vision</span>
                  <sup>&reg;</sup>
                 </span>
};

export const STEPS = {
  ENTER_USERNAME: 0,
  ENTER_PASSWORD: 1,
  ENTER_MFA: 2,
  GO_TO_IDP_TO_LOGIN: 3,
  SETUP_MFA: 4,
};

export const LOGIN_COOKIES = {
  EMAIL: "EMAIL",
  IDENTITY_PROVIDER: "IDENTITY_PROVIDER",
  IDENTITY_PROVIDER_ATTEMPT: "IDENTITY_PROVIDER_ATTEMPT",
};

export const SESSION_STORAGE = {
  RETURN_TO: "RETURN_TO",
};

/**
 * This renders the login page.
 */
// i18next-extract-mark-ns-start users
function UserLogin(props) {
  const {t} = props;

  CognitoHelper.configureUserPool();

  const [step, setStep] = useState(STEPS.ENTER_USERNAME);
  const [username, setUsername] = useState(Cookies.get(LOGIN_COOKIES.EMAIL) || "");
  const [password, setPassword] = useState("");
  const [mfaSecret, setMfaSecret] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [forceMfa, setForceMfa] = useState(false);
  const [error, setError] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [showNewPasswordPopup, setShowNewPasswordPopup] = useState(false);
  const [showNewPinPopup, setShowNewPinPopup] = useState(false);
  const [accessInformation, setAccessInformation] = useState(null);
  const [identityProvider, setIdentityProvider] = useState(null);
  const newPasswordPopup = useRef(null);
  const newPinPopup = useRef(null);
  const passwordInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // This is the equivalent to the old componentDidMount that sets error messages on first render
  useEffect(() => {
    document.title = t("User login to QbDVision");

    const reason = UIUtils.getParameterByName("reason");
    const returnTo = UIUtils.getParameterByName("returnTo");
    const externalLoginError = sessionStorage["externalLoginError"];
    let error;

    if (reason === "Terms") {
      error = t("You must accept the terms and conditions to access this site.");
    } else if (reason === "Expired") {
      error = t("Your session timed out.  Please login again.");
    } else if (reason === "UserDisabled") {
      error = t(CommonSecurity.USER_DISABLED_MESSAGE);
    } else if (reason === "CognitoDeveloperError") {
      error = t(CommonSecurity.COGNITO_DEVELOPER_ERROR);
    } else if (externalLoginError) {
      error = externalLoginError;
      sessionStorage["externalLoginError"] = "";
    }

    // Save this for later when the login is successful, potentially after going to the SSO.
    if (returnTo) {
      Logger.info(() => "Setting return to cookie:", returnTo);
      sessionStorage[SESSION_STORAGE.RETURN_TO] = returnTo;
    }
    if (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    // UseEffect can't return a promise, so we create a dummy method and call it.
    const submitMFACodeIfLongEnough = async() => {
      if (mfaCode?.length === 6) {
        if (step === STEPS.SETUP_MFA) {
          await handleMfaSubmission(null, "setup", mfaCode);
        } else if (step === STEPS.ENTER_MFA) {
          await handleMfaSubmission(null, "verify", mfaCode);
        }
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    submitMFACodeIfLongEnough();
  }, [mfaCode]);

  useEffect(() => {
    if (error) {
      showErrorMessage(error);
    } else {
      UIUtils.clearError();
    }
  }, [error]);

  useEffect(() => {
    const syncAuthAcrossTabs = (event) => {
      if (event.key === "IS_LOGGED_IN" && event.newValue === "true") {
        // Reload current tab to sync state
        window.location.reload();
      }
    };
  
    window.addEventListener("storage", syncAuthAcrossTabs);
    return () => window.removeEventListener("storage", syncAuthAcrossTabs);
  }, []);

  // This is the code that handles the effect of setting the step number
  useEffect(() => {
    Logger.info(() => "Step change detected to step:", step);
    let stepFromURL = getStepFromURL();
    if (step !== stepFromURL) {
      navigate("/index.html" + (step === STEPS.ENTER_USERNAME ? "" : ("?step=" + step)));
    }

    switch (step) {
      case STEPS.ENTER_USERNAME:
        setPassword("");
        setCognitoUser(null);
        break;
      case STEPS.ENTER_PASSWORD:
        passwordInputRef.current.focus();
        break;
      case STEPS.ENTER_MFA:
        UIUtils.setHideLoadingOnAjaxStop(true);
        UIUtils.hideLoadingImage();
        break;
      case STEPS.GO_TO_IDP_TO_LOGIN:
        Cookies.set(LOGIN_COOKIES.EMAIL, username);
        Cookies.set(LOGIN_COOKIES.IDENTITY_PROVIDER, identityProvider);
        Cookies.set(LOGIN_COOKIES.IDENTITY_PROVIDER_ATTEMPT, 1);

        Auth.federatedSignIn({provider: identityProvider}).catch(error => handleLoginError(error));
        break;
      case STEPS.SETUP_MFA:
        break;
    }
  }, [step]);

  /**
   * This method is called every time the history changes (i.e. the user clicks the back button).
   */
  useEffect(() => {
    let stepFromURL = getStepFromURL();
    Logger.info(() => "History changed. Step:", step, "Step URL Value:", stepFromURL);

    if (step !== stepFromURL) {
      UIUtils.showLoadingImage();
      let skipGoingToStep = false;

      if (stepFromURL < step && stepFromURL === STEPS.ENTER_PASSWORD) {
        // Clear out the password when they click the back button
        setPassword("");
      } else if (stepFromURL > step && !password && step === STEPS.ENTER_PASSWORD) {
        // Don't let them go forward without entering a password.
        skipGoingToStep = true;
        navigate("/index.html?step=" + STEPS.ENTER_PASSWORD, {replace: true});
      } else if (stepFromURL < step && stepFromURL === STEPS.ENTER_USERNAME) {
        // Clear out future errors when they go back.
        setError(null);
      }

      if (!skipGoingToStep) {
        setStep(stepFromURL);
      }
      UIUtils.hideLoadingImage();
    }
  }, [location]);

  /**
   * This is called after the user enters their email address, and we need to figure out what path to go next.
   * @param event The UI click event that cause this to be called.
   */
  const handleCheckValidEmail = (event) => {
    UIUtils.ignoreHandler(event);

    clearError();
    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage();

    UIUtils.secureAjaxGET(`users/userAPI?activity=checkLoginFlow&username=${encodeURIComponent(username)}`,
      null, true, handleLoginError).done(user => {
      const {userExists, identityProvider, forceMfa} = user;

      if (identityProvider) {
        setIdentityProvider(identityProvider);
        setError(null);
        setStep(STEPS.GO_TO_IDP_TO_LOGIN);
      } else {
        Cookies.remove(LOGIN_COOKIES.IDENTITY_PROVIDER, identityProvider);

        setError(null);
        setStep(STEPS.ENTER_PASSWORD);
        setForceMfa(forceMfa);

        UIUtils.setHideLoadingOnAjaxStop(true);
        UIUtils.hideLoadingImage();
      }
    }).fail((error) => {
      setStep(STEPS.ENTER_USERNAME);
      handleLoginError(error);
    });
  };

  /**
   * This is called when a user has entered their password and they expect to be logged in. The MFA code or a password
   * reset may still need to come next.
   * @param event The UI click event that cause this to be called.
   */
  const handleUserLogin = (event) => {
    UIUtils.ignoreHandler(event);

    clearError();
    UIUtils.showLoadingImage();
    $.ajax({
      url: UIUtils.getURL(`users/userAPI?activity=getUsername&username=${encodeURIComponent(username)}`),
      type: "GET",
      global: false,
      idempotent: true,
      error: result => {
        let responseJSON = result.responseJSON;
        let error = responseJSON && responseJSON.error ? responseJSON.error : responseJSON;

        if (error && (
          (error.stack && error.stack.startsWith("InvalidParameterException"))
          || (error.code && error.code === "InvalidParameterException")
        )) {
          UIUtils.hideLoadingImage();
          handleLoginError(t("Incorrect username or password"));
        } else {
          handleLoginError(error);
        }
      }
    }).done((username) => {
      // noinspection JSIgnoredPromiseFromCall
      new RetryWrapper(() => attemptLogin(username, password),
        (ignored, waitInMS) => UIUtils.showError(t("Cannot login to QbDVision. Retrying in {{ retryWait }} seconds...", {retryWait: waitInMS / 1000}))
      ).retryFunction();
    });
  };

  /**
   * This is tightly integrated but separated out from the handleUserLogin handler above so that it can be retried.
   */
  const attemptLogin = async(username, password) => {
    try {
      let user = await Auth.signIn(username, password);
      setCognitoUser(user);

      if (newPasswordPopup) {
        $(newPasswordPopup.current).modal("hide");
      }

      // handle Cognito challenges
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        newPasswordRequired(user);
      } else if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        clearError();
        setStep(STEPS.ENTER_MFA);
        UIUtils.hideLoadingImage();
      } else if (user.challengeName === "MFA_SETUP" || forceMfa) {
        await handleMfaSetup(user);
        clearError();
        UIUtils.hideLoadingImage();
      } else {
        logUserIn(user);
      }
    } catch (error) {
      Logger.info(() => "Error received. Maybe retryable:", Log.error(error));
      if (error.code === "PasswordResetRequiredException") {
        try {
          const data = await Auth.forgotPassword(username);
          // successfully initiated reset password request
          Logger.info(() => "CodeDeliveryData from forgotPassword: " + JSON.stringify(data));
          window.location.href = UIUtils.getSecuredURL(`./users/resetPassword.html?username=${encodeURIComponent(username)}`);
        } catch (error) {
          handleLoginError(error);
          throw error;
        }
      } else if (CognitoHelper.isCognitoErrorRetryable(error)) {
        Logger.warn(() => "Retrying because of " + UIUtils.stringify(error));
        throw new RetryPleaseError();
      } else {
        if (["NotAuthorizedException", "InvalidParameterException", "UserNotFoundException"].includes(error.code)) {
          const errorMessage = t("Incorrect username or password");
          error.code = error.code === "UserNotFoundException" ? "NotAuthorizedException" : error.code;
          handleLoginError(errorMessage);
        } else {
          handleLoginError(error);
        }
      }
    }
  };

  const newPasswordRequired = (cognitoUser) => {
    // User was signed up by an admin and must provide new
    // password and required attributes, if any, to complete
    // authentication.

    Logger.info(() => "New Password required received.");
    setShowNewPasswordPopup(true);
    setCognitoUser(cognitoUser);
    UIUtils.hideLoadingImage();
  };

  const logUserIn = (user) => {
    Logger.verbose(() => "Received successful login: " + UIUtils.stringify(user));
    Logger.info(() => "Attributes: " + UIUtils.stringify(user.attributes));
    UIUtils.recordSuccessfulLogin(user, user.attributes);
  };

  const hidePasswordModal = (mfaSetupChallenge) => {
    if (showNewPasswordPopup) {
      $(newPasswordPopup.current).modal("hide");
      document.body.classList.remove("modal-open");
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
      setShowNewPasswordPopup(false);
    }
    if (mfaSetupChallenge?.username) {
      handleMfaSetup(cognitoUser).then(() => {
        UIUtils.hideLoadingImage();
      });
    }
  };

  const hidePinModal = () => {
    if (newPinPopup) {
      $(newPinPopup.current).modal("hide");
    }

    setShowNewPinPopup(false);
  };

  const handleMfaSetup = async(user) => {
    setStep(STEPS.SETUP_MFA);
    const mfaSecret = await Auth.setupTOTP(user);
    setMfaSecret(mfaSecret);
  };

  /**
   * Handles submitting an MFA code, either for setup or for login after setup.
   * @param event The UI click event that cause the submission
   * @param action {string} Either "setup" or "verify" depending on if the goal is to set up MFA for their account or verify an existing user for login.
   * @param passedCode The MFA code entered by the user.
   * @returns {Promise<void>}
   */
  const handleMfaSubmission = async(event, action, passedCode) => {
    UIUtils.ignoreHandler(event);
    clearError();
    UIUtils.showLoadingImage();
    let user = cognitoUser;
    let code = passedCode || mfaCode;

    try {
      if (!code || code.length !== 6) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(`Invalid authentication code. Please make sure you enter all 6 digits.`);
      }
      if (action === "setup") {
        await Auth.verifyTotpToken(user, code);
        await Auth.setPreferredMFA(user, "TOTP");
      } else if (action === "verify") {
        user = await Auth.confirmSignIn(user, code, "SOFTWARE_TOKEN_MFA");
      }
      logUserIn(user);
    } catch (errorParam) {
      let error = errorParam;
      if (error.message === "Code mismatch") {
        error = new Error(`Invalid authentication code. Please make sure you enter all 6 digits.`);
      }
      handleLoginError(error);
    }
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case "username":
        setUsername(event.target.value);
        break;
      case "mfaCode":
        setMfaCode(event.target.value);
        break;
      case "password" :
        setPassword(event.target.value);
        break;
      default:
        throw new Error("The handleChange method needs to be updated to handle: " + event.target.name);
    }
  };

  const handleLoginError = (errorParam) => {
    if (typeof errorParam === "string") {
      errorParam = new Error(errorParam);
      errorParam.isValidation = true;
    }

    errorParam.username = username;
    errorParam.identityProvider = identityProvider;

    UIUtils.setHideLoadingOnAjaxStop(true);
    UIUtils.hideLoadingImage();

    Logger.error(() => "Login error", Log.error(errorParam));
    setError(errorParam);
  };

  /**
   * This clears the error message. The Error bar is taken care of by the useEffect above.
   */
  const clearError = () => {
    setError(null);
  };

  /**
   * This is called after the user has successfully logged in using their SSO (like Okta, Google, etc).
   * @param user
   */
  const handleExternalUserLogin = (user) => {
    Logger.info(() => "User:", Log.object(user));

    if (user) {
      const {name, sub, email, accessToken, username} = user;

      if (user.newSigningPinIsRequired) {
        Logger.info(() => "Requesting new signing pin...");
        setAccessInformation({
          encodedAccessToken: accessToken,
          ...user,
        });
        setShowNewPinPopup(true);
      } else {
        UIUtils.showLoadingImage();
        UIUtils.recordSuccessfulLogin({
          encodedAccessToken: accessToken,
        }, {name, sub, email, username});
      }
    } else {
      handleLoginError(t("Email/Username is incorrect"));
    }
  };

  function getStepFromURL() {
    const stepValue = UIUtils.getParameterByName("step");
    return UIUtils.isInteger(stepValue) ? UIUtils.parseInt(stepValue) : STEPS.ENTER_USERNAME;
  }

  const showErrorMessage = (error) => {
    if (typeof error === "string") {
      error = new Error(error);
    }

    error.username = username;
    error.cognitoUser = username;
    error.isValidation = true;
    UIUtils.defaultFailFunction(error);
  };

  const reason = UIUtils.getParameterByName("reason");
  let returnTo = UIUtils.getParameterByName("returnTo") || sessionStorage[SESSION_STORAGE.RETURN_TO];
  if (reason) {
    if (!(reason === "Expired" && UIUtils.getAccessToken())) {
      UIUtils.clearSessionInfoForLogout();
      // Add back the returnTo URL so it doesn't get lost.
      sessionStorage[SESSION_STORAGE.RETURN_TO] = returnTo;
    }
  }

  if (UIUtils.getCognitoUUID()) {
    if (!returnTo) {
      returnTo = UIUtils.FRONT_END_URL + UIUtils.DEFAULT_RETURN_TO_URL;
    }
    window.location.href = UIUtils.getSecuredURL(returnTo, {
      enforceHostWithinQbDVisionDomain: true
    });
    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    return null;
  }

  // This helps debug SSO issues when there are multiple redirects.
  Logger.info(() => "URL:", window.location.href);

  const errorDescription = UIUtils.getFragmentByName("error_description");
  const accessToken = UIUtils.getFragmentByName("access_token");
  const email = Cookies.get(LOGIN_COOKIES.EMAIL);

  if (errorDescription && !error) { // If there is an error, don't overwrite it.
    handleLoginError(errorDescription);
  } else if (accessToken) {

    UIUtils.secureAjaxGET(`users/userAPI?activity=userExists&accessToken=${accessToken}`, {email}, false, (error) => {

      const externalIdentityProvider = Cookies.get(LOGIN_COOKIES.IDENTITY_PROVIDER);
      const errorText = error.responseText;

      if (externalIdentityProvider && errorText) {
        sessionStorage["externalLoginError"] = errorText;
        Logger.error(() => "Caught error while trying to use external login:", errorText);
        CommonURLs.logoutExternalUser();
      }
    }).done((user) => handleExternalUserLogin(user));
  } else {
    Logger.verbose("No IdP code or error detected.");
  }

  const isMfaSetupStep = !!mfaSecret;
  const stepHeader = step === STEPS.SETUP_MFA ? "mfaSetup" : step === STEPS.ENTER_MFA ? "mfaSubmission" : "defaultHeader";
  const showCreateCompanyButton = !CommonUtils.isCommercialEnvironment();

  return (
    <div>
      <div className="container-fluid">
        <CompanyLoginHeader firstHeader={
          <Trans t={t}>{HEADERS[stepHeader]}</Trans>} customLogoSize={isMfaSetupStep ? 128 : null}
        />
        <br />
        <div className={isMfaSetupStep ? "center-double-column-grid" : "center-single-column-grid"}>
          <div className="row">
            <div className="col-sm-12">
              <ErrorBar className={"error-bar login-error-bar"} />
              {step === STEPS.ENTER_USERNAME || step === STEPS.ENTER_PASSWORD ? (
                <UsernamePasswordForm t={t}
                                      username={username}
                                      password={password}
                                      step={step}
                                      onInputChange={handleChange}
                                      onCheckValidEmail={handleCheckValidEmail}
                                      onUserLogin={handleUserLogin}
                                      passwordInputRef={passwordInputRef}
                                      shouldShowCreateCompanyButton={showCreateCompanyButton}
                />
              ) : step === STEPS.SETUP_MFA ?
                <MFASetup mfaSecret={mfaSecret}
                          onInputChange={handleChange}
                          onMFASubmission={handleMfaSubmission}
                          username={username}
                />
                : step === STEPS.ENTER_MFA ?
                  <MFASubmission t={t}
                                 onInputChange={handleChange}
                                 onMFASubmission={handleMfaSubmission}
                                 mfaCode={mfaCode}
                  />
                  : null
              }</div>
          </div>
        </div>
      </div>
      {showNewPasswordPopup ?
        <UserNewPasswordPopup modalRef={newPasswordPopup}
                              onHideModal={hidePasswordModal}
                              cognitoUser={cognitoUser}
                              id="userNewPasswordPopup"
        /> : ""}
      {showNewPinPopup ?
        <UserNewSigningPinPopup modalRef={newPinPopup}
                                onHideModal={hidePinModal}
                                accessInformation={accessInformation}
                                id="UserNewSigningPinPopup"
        /> : ""}
      <div className="footer-login">
        <FooterBar />
      </div>
    </div>
  );
}

export default I18NWrapper.wrap(UserLogin, "users");
// i18next-extract-mark-ns-stop users
