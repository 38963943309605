const CommonUtils = require("../generic/common_utils");

class LabelFormatDefaultStrategy {
  constructor(modelFormatter) {
    this.modelFormatter = modelFormatter;
  }

  /**
   * Gets a label fit for displaying a record for the user (given the record itself)
   * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
   * @param options {ICustomLabelOptions} The options for formatting the custom label. * @return {string}
   */
  getRecordCustomLabelForDisplay = function(record, options = {}) {
    return this.modelFormatter.getRecordCustomLabelForDisplay(record, options);
  };

  getRecordCustomLabelForDisplayAlternate(record, options = {}) {
    return this.modelFormatter.getRecordCustomLabelForDisplayAlternate(record, options);
  }

  getRecordCustomLabelForDisplayInReports(record, options ={}) {
    return this.getRecordCustomLabelForDisplay(record, options);
  }

  getRecordNameFromLabel = function(label, typeCode) {
    let regexRule = new RegExp(`${typeCode}-\\d+ - (.*)`);
    const match = regexRule.exec(label);
    if (!match) {
      throw new Error(`Cannot extract name from label ${label} for record type ${typeCode}`);
    }

    return match[1];
  };

  getRecordKeyFromLabel = function(label) {
    if (!label) {
      return;
    }

    const labelArr = label.split(" - ");
    return labelArr[0];
  };

  parseLabel(label) {
    let currentSliced = label && label.split(" - ");
    if (currentSliced?.length > 1) {
      const key = currentSliced[0];
      const keyInfo = CommonUtils.parseKey(key);
      const typeCode = keyInfo.typeCode;
      if (keyInfo?.id) {
        currentSliced[0] = `${typeCode}-${keyInfo.id}`;
      } else {
        currentSliced[0] = typeCode;
      }
    }

    return currentSliced;
  }

  getLabelIncludingLastVersionInformation(data) {
    let version = data.lastVersion;
    if (!version) {
      version = data;
    }

    return `${data.typeCode}-${data.id} - ${version.name} - ${version.majorVersion}.${version.minorVersion}`;
  }

  getIdNameColumnLabel() {
    return "ID - Name";
  }

  getBatchAttributeLabel(recordTypeAndId, name) {
    return `${recordTypeAndId} ${name}`;
  }
}

module.exports = {
  LabelFormatDefaultStrategy,
};