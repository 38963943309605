const {LabelFormatDimmedStrategy} = require("./label_format_dimmed_strategy");
const {LabelFormatDefaultStrategy} = require("./label_format_default_strategy");
const {ModelFormatter} = require("../generic/common_model_formatter");

let singletonInstance;

class LabelDirector {
  constructor(modelFormatter) {
    this.modelFormatter = modelFormatter;
    this.labelFormatStrategy = this.getLabelFormatStrategy("default");
    this.labelFormatDimmedStrategy = this.getLabelFormatStrategy("dimmed");
  }

  static instance() {
    if (!singletonInstance) {
      const modelFormatter = ModelFormatter.instance();
      singletonInstance = new LabelDirector(modelFormatter);
    }
    return singletonInstance;
  }

  setLabelFormat = function(labelFormat) {
    if (labelFormat) {
      this.labelFormatStrategy = this.getLabelFormatStrategy(labelFormat);
    }
  };

  isDimmedLabelFormat = function() {
    return this.labelFormatStrategy instanceof LabelFormatDimmedStrategy;
  };

  getLabelFormatStrategy = function(labelFormat) {
    switch (labelFormat) {
      case "dimmed":
        return new LabelFormatDimmedStrategy(this.modelFormatter);
      case "default":
        return new LabelFormatDefaultStrategy(this.modelFormatter);
      default:
        throw new Error(`Label form ${labelFormat} not implemented`);
    }
  };

  /**
   * Returns a label fit for displaying a record for the user
   * @param typeCode {string} The type code of the model of the record
   * @param id {number} The id of the record (usually the id field in the database)
   * @param name {string} The name of the record (usually the value of the name field in database)
   * @param isTypeCodeTranslated {boolean} If true, assumes that the type code is translated (default is false).
   * @return {string|string}
   * @deprecated Use {@link getRecordCustomLabelForDisplay}
   */
  getRecordLabelForDisplay = function(typeCode, id, name, isTypeCodeTranslated = false) {
    return this.labelFormatStrategy.getRecordCustomLabelForDisplay({typeCode, id, name}, {isTypeCodeTranslated});
  };

  getRecordNameFromLabel = function(label, typeCode) {
    return this.labelFormatStrategy.getRecordNameFromLabel(label, typeCode);
  };

  getRecordKeyFromLabel = function(label) {
    return this.labelFormatStrategy.getRecordKeyFromLabel(label);
  };

  /**
   * Gets a label fit for displaying a record for the user (given the record itself)
   * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
   * @param options {ICustomLabelOptions} The options for formatting the custom label. * @return {string}
   */
  getRecordCustomLabelForDisplay = function(record, options = {}) {
    return this.labelFormatStrategy.getRecordCustomLabelForDisplay(record, options);
  };

  getRecordCustomLabelForDisplayAlternate = function(record, options=  {}) {
    return this.labelFormatStrategy.getRecordCustomLabelForDisplayAlternate(record, options);
  };

  /**
   * Get record label used in Configurable Tables and Document Builder.
   * The goal is to remove the IDs from record label.
   */
  getRecordCustomLabelForDisplayInReports = function(record, options=  {}) {
    return this.labelFormatStrategy.getRecordCustomLabelForDisplayInReports(record, options);
  };

  /**
   * Parse a label extracting the name and typecode from it
   * @param label
   * @returns {string[]}
   */
  parseLabel(label) {
    return this.labelFormatStrategy.parseLabel(label);
  }

  /**
   * Get record label using the information from the last version
   * @param data
   * @returns {string|*}
   */
  getLabelIncludingLastVersionInformation(data) {
    return this.labelFormatStrategy.getLabelIncludingLastVersionInformation(data);
  }

  getIdNameColumnLabel() {
    return this.labelFormatStrategy.getIdNameColumnLabel();
  }

  getBatchAttributeLabel(recordTypeAndId, name) {
    return this.labelFormatStrategy.getBatchAttributeLabel(recordTypeAndId, name);
  }
}

module.exports = {
  LabelDirector,
};
